import React from "react";
import "./image-card.scss";
import { Heading } from "../Heading/Heading";
import { Button } from "../Button/Button";
import { navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const CardWithImage = ({
  title,
  description,
  ctaText,
  ctaLink,
  image,
}) => {
  const parsedImage = getImage(image);
  return (
    <>
      <div
        className="image-card"
        onClick={() => navigate(ctaLink)}
        role="presentation"
      >
        <div className="image-card__holder">
          <GatsbyImage
            image={parsedImage}
            className="image-card__image rspimg"
            alt={title}
          />
          <div className="image-card__text">
            <Heading level="h3">{title}</Heading>
            <div className="description">{description}</div>
            <Button link={ctaLink} special white>
              {ctaText}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
