import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from "gatsby";
import Layout from '../components/layout/layout'
import Fold from '../components/Fold/Fold';
import ApplicationForm from '../components/ApplicationForm/AplicationForm';
import { BoxSection } from '../components/BoxSection/BoxSection';
import { CardWithImage } from "../components/CardWithImage/CardWithImage";

const IMAGE_QUERY = graphql`
{
  WhoWeAre: file(
    relativePath: { eq: "careers/who-are-we.jpg" }
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 800
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
  WhatWeDo: file(
    relativePath: { eq: "careers/what-we-do.jpg" }
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 700
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
}
`


const Contact = ({ data }) => {
  const tempImage = useStaticQuery(IMAGE_QUERY);
  return (
    <Layout
      pageTitle="Open Application"
      description="The job you are looking for is not on the open positions list, no problem we’re always on the lookout for new talent."
    >
      <Fold
        title={<Fragment>Open <span>Application</span> </Fragment>}
        description="The job you are looking for is not on the open positions list, no problem we’re always on the lookout for new talent."
        centered
      >
      </Fold>
      <ApplicationForm
        position="Open Application"
        showApplytext
      />
      <BoxSection>
      <CardWithImage
          title="Who are we?"
          description={
            <Fragment>
              <p>
                Discover how our scalable low-latency solutions enable
                high-speed transactions for firms across the finance sector.
              </p>
            </Fragment>
          }
          ctaText="Learn more"
          ctaLink="/about"
          image={tempImage.WhoWeAre}
        />

        <CardWithImage
          title="What we do?"
          description={
            <Fragment>
              <p>
                Discover how our scalable low-latency solutions enable
                high-speed transactions for firms across the finance sector.
              </p>
            </Fragment>
          }
          ctaText="Learn more"
          ctaLink="/our-work"
          image={tempImage.WhatWeDo}
        />
      </BoxSection>
    </Layout>
  );
};

export default Contact;

