import React, { Fragment, useRef, useState } from 'react';
import './application-form.scss';
import { Button } from '../Button/Button';
import { IntroHolder } from '../IntroHolder/IntroHolder';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import DropzoneComponent from 'react-dropzone-component';
import "../../../node_modules/dropzone/dist/min/dropzone.min.css";


const phoneRegExp = /^[0-9 ()+-]+$/
const schema = yup.object({
  name: yup.string().required('Please tell us your name').min(3, `Your name has to have at least 3 letters`),
  email: yup.string().email('Please check your email, its not valid').required('We need your email to contact you'),
  phone: yup.string().required('We need your phone to contact you').matches(phoneRegExp, `Please type in a valid phone number`).min(5, `Your phone has to have at least 5 numbers`),
  links: yup.string().nullable(),
  aboutYourself: yup.string(),
  gdpr: yup.boolean().oneOf([true], 'Please accept our privacy policy'),
}).required();

const ApplicationForm = ({ position, showApplytext }) => {
  const [fileErrorMessage, setFileErrorMessage] = useState(null);
  const dropzoneRef = useRef();
  const {
    register,
    handleSubmit,
    onError,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const showSuccessMessage = () => {
    setIsSuccess(true);
    setTimeout(() => setIsSuccess(false), 5000);
  }

  const dropzoneConfig = { postUrl: `${process.env.GATSBY_API_URL}/job-form` };
  const djsConfig = {
    maxFilesize: 10,
    parallelUploads: 6,
    maxFiles: 6,
    acceptedFiles: ".docx,.doc,.pdf",
    autoProcessQueue: false,
    addRemoveLinks: true,
    uploadMultiple: true,
    paramName: () => 'file',
  }

  const handleInit = (dropzoneInstance) => {
    dropzoneRef.current = dropzoneInstance;
  }

  const handleAddedFile = () => {
    setFileErrorMessage(null);
  }

  const handleSendingMultiple = (data, xhr, formData) => {
    setLoading(true);
    setIsError(false);
    setIsSuccess(false);
    const formValues = getValues();
    for (const key in formValues) {
      if (key === 'field') {
        formData.append(key, formValues[key][1])
      } else {
        formData.append(key, formValues[key])
      }
    }
  }

  const handleSubmitSuccessful = () => {
    setLoading(false);
    showSuccessMessage();

    reset({
      jobPosition: "",
      name: "",
      email: "",
      phone: "",
      links: "",
      aboutYourself: "",
      gdpr: false,
      files: ""
    })
    dropzoneRef.current.removeAllFiles();
    setFileErrorMessage(null);
  }

  const handleSubmitError = () => {
    setLoading(false);
    setIsError(true);
  }

  const eventHandlers = {
    init: handleInit,
    addedfile: handleAddedFile,
    sendingmultiple: handleSendingMultiple,
    successmultiple: handleSubmitSuccessful,
    error: handleSubmitError,
  }

  const onSubmit = () => {
    if (dropzoneRef.current?.getQueuedFiles().length > 0) {
      dropzoneRef.current?.processQueue();
    }
  };

  const validateFileUploads = () => {
    const hasQueuedFiles = dropzoneRef.current?.getQueuedFiles().length > 0;

    if (!hasQueuedFiles) {
      setFileErrorMessage('CV is mandatory')
    }
  }

  return (
    <>
      <div className="container">
        <div className="gr-12">
          {showApplytext &&
            <IntroHolder
              title={<Fragment>Apply <span>Now</span> </Fragment>}
              level="h3"
              centered
              small
            />
          }
          <div className="application-form">
            <div role="form" className="application-form__holder">
              <div className="screen-reader-response"></div>
              <form
                onSubmit={(e) => {
                  handleSubmit(onSubmit)(e);
                  validateFileUploads();
                }}
              >

                <input type="hidden" {...register("jobPosition")} value={position} className="ignore" />

                <div className="row">
                  <div className="gr-12 gr-6@md">
                    <fieldset className="form__fieldset">
                      <input type="text" className={errors.name && 'error'}  {...register("name")} size="40"
                        placeholder="Full name" />
                      <label htmlFor="name">Full name</label>
                      <label className="error">{errors.name?.message}</label>
                      {errors.name && <div className="error-icon"></div>}
                    </fieldset>
                  </div>
                  <div className="gr-12 gr-6@md">
                    <fieldset className="form__fieldset">
                      <input type="email" className={errors.email && 'error'} {...register("email")} size="40"
                        placeholder="E-mail" />
                      <label htmlFor="email">E-mail</label>
                      <label className="error">{errors.email?.message}</label>
                      {errors.email && <div className="error-icon"></div>}
                    </fieldset>
                  </div>
                </div>
                <div className="row">
                  <div className="gr-12 gr-6@md">
                    <fieldset className="form__fieldset">
                      <input type="tel" className={errors.phone && 'error'} {...register("phone")} size="40"
                        placeholder="Phone" />
                      <label htmlFor="phone">Phone</label>
                      <label className="error">{errors.phone?.message}</label>
                      {errors.phone && <div className="error-icon"></div>}
                    </fieldset>
                  </div>
                  <div className="gr-12 gr-6@md">
                    <fieldset className="form__fieldset">
                      <input className={errors.links && 'error'} {...register("links")} size="40"
                        placeholder="Portfolio Link (GitHub, Dribbble, etc.)" />
                      <label htmlFor="links">Portfolio Link (GitHub, Dribbble, etc.)</label>
                      <label className="error">{errors.links?.message}</label>
                      {errors.links && <div className="error-icon"></div>}
                    </fieldset>
                  </div>
                </div>
                <div className="row">
                  <div className="gr-12">
                    <fieldset className="form__fieldset form__fieldset--dropzone">
                      <DropzoneComponent
                        config={dropzoneConfig}
                        eventHandlers={eventHandlers}
                        djsConfig={djsConfig}
                      >
                        <div className="dz-message">
                          Attach your CV and other documents (.docx,.doc,.pdf)*
                        </div>
                      </DropzoneComponent>
                      {fileErrorMessage && <label className="error">{fileErrorMessage}</label>}
                    </fieldset>
                  </div>
                </div>
                <div className="row">
                  <div className="gr-12">
                    <fieldset className="form__fieldset form__fieldset--about">
                      <textarea {...register("aboutYourself")} placeholder="Something about yourself" rows="4" />
                      <label htmlFor="aboutYourself">Something about yourself</label>
                      <label className="error">{errors.aboutYourself?.message}</label>
                      {errors.aboutYourself && <div className="error-icon"></div>}
                    </fieldset>
                  </div>
                </div>
                <div className="row">
                  <div className="gr-12">
                    <fieldset className="form__fieldset form__fieldset--gdpr">
                      <div className="checkbox-group">
                        <input type="checkbox" {...register("gdpr")} id="gdpr" />
                        <label htmlFor="gdpr">I allow Kickstage to store my data for 24 months as well as contact me
                          regarding future job opportunities</label>
                        <label className="error">{errors.gdpr?.message}</label>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="row">
                  {isSuccess &&
                    <div className="gr-12">
                      <div className="form__notification">
                        <span>Your application has been sent. We'll be in touch!</span>
                      </div>
                    </div>
                  }
                  {isError &&
                    <div className="gr-12">
                      <div className="form__error">
                        <span>Something went wrong. Please try again later.</span>
                      </div>
                    </div>
                  }
                  <div className="gr-12">
                    <Button
                      type="submit"
                      submitBtn
                      primary
                      fullWidth
                      loading={loading}
                      disabled={loading}
                      data-sitekey="6Ld-SrEZAAAAAD9uhrzNWBaTDuQRCTuFQnKI4z54"
                      data-callback="recaptchaCallback"
                      data-badge="inline"
                      data-size="invisible"
                      onClick={() => handleSubmit(onSubmit, onError)}
                    >
                      {loading ? 'Sending...' : 'Send application'}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default ApplicationForm
