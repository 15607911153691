import React from "react";
import "./box-section.scss";
import { Container } from "../Container/Container";

export const BoxSection = ({ children }) => {
  return (
    <>
      <section className="box-section">
        <Container>
          <div className="box-section__holder">{children}</div>
        </Container>
      </section>
    </>
  );
};
