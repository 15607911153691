import React from 'react';

export const Container = ({ modifer, children }) => {

  return (
    <>
      <div className={`container ${modifer}`}>
        {children}
      </div>
    </>
  );
};